import React from "react";
import LayoutForm from "../../components/layoutForm";
import Seo from "../../components/atoms/seo";
import { graphql, navigate } from "gatsby";
import { withTranslation } from "react-i18next";
import Loading from "../../components/atoms/loading";
import queryString from "query-string";
import { LoginEHS } from "../../services/ad";
import {
  getBusinessUnit,
  getUserInfo,
  isBrowser,
  setUserInfo,
} from "../../services/auth";
import styled from "styled-components";
import Watermark from "../../images/EHS Logo lg.png";

const WaterMark = styled.div`
  position: fixed;
  top: 25%;
  text-align: center;
  opacity: 0.03;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

class SSO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
    };
    if (isBrowser()) {
      var query = queryString.parse(window.location.search);
      if (query !== undefined && query["token"] !== undefined) {
        this.login(query["token"], query["redirect"]);
      }
    }
  }
  login = (token, redirect) => {
    var val = redirect;
    LoginEHS(token).then((result) => {
      if (result != null) {
        var savedUser = getUserInfo();
        if (savedUser !== null && savedUser !== "") {
          savedUser.Name = result.Name;
          savedUser.Email = result.Email;
          savedUser.EmployeeNumber = result.EmployeeId;
          savedUser.ManagerEmail = result.ManagerEmail;
          savedUser.Department = result.Department;
          savedUser.UserLocation = result.OfficeLocation;
          savedUser.BusinessUnit = getBusinessUnit();
          savedUser.SSO = true;
          setUserInfo(savedUser);
        } else {
          var user = {
            EmployeeType: "Employee",
            Name: result.Name,
            Email: result.Email,
            ManagerEmail: result.ManagerEmail,
            EmployeeNumber: result.EmployeeId,
            Department: result.Department,
            UserLocation: result.OfficeLocation,
            BusinessUnit: getBusinessUnit(),
            SSO: true,
          };
          setUserInfo(user);
        }
      }
      if (isBrowser()) {
        if (val) {
          navigate("/forms/incident-report?id=" + val);
        } else {
          navigate("/forms/incident-report");
        }
      }
    });
  };
  render() {
    return (
      <LayoutForm
        headerTitle={
          <>
            EHS&nbsp;<span style={{ color: "#707070" }}>Incident Portal</span>
          </>
        }
      >
        <Seo
          title={this.props.t("EHS Incident Portal")}
          keywords={[`PerkinElmer`]}
        />
        <WaterMark>
          <img alt="backgroundImg" src={Watermark}></img>
        </WaterMark>
        <br /> <br /> <br /> <br />
        <Loading message={"Loading User Profile"} size="lg"></Loading>
      </LayoutForm>
    );
  }
}
export default withTranslation()(SSO);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "incident-report"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
